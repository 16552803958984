import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useConmmentPost() {
    const [posting, setPosting] = useState(false);
    const abortControllerRef = useRef(null);

    const postComment = useCallback(async ({ commentOnID, comment }) => {
        if (!comment) return;
        //use fetch to post comment
        setPosting(true);
        const req = {
            comment: comment,
            commentOnID: commentOnID,
        };
        console.log('req', req);
        abortControllerRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/comments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify(req),
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred post a comment');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error posting a comment:', error);
            }
            throw error;
        } finally {
            setPosting(false);
        }
    }, []);

    postComment.propTypes = {
        commentOnID: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
    };

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { posting, postComment, abort };
}

useConmmentPost.propTypes = {};

export default useConmmentPost;
