import { useRef, useState, useCallback, useEffect } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';
import PropTypes from 'prop-types';
import { useWs } from '../contexts/WsContext';
import { PubSubDataType, PubSubCMD } from '../wsManager';

function useComments() {
    const [fetching, setFetching] = useState(false);
    const [comments, setComments] = useState([]);
    const abortControllerRef = useRef(null);
    const fetchParamsRef = useRef({ commentOnID: null, selfOnly: false }); // Store the current query params
    const { wsManager } = useWs();

    useEffect(() => {
        if (!wsManager) return;
        const handleMessage = (data) => {
            // console.log('useComments received message:', data);
            try {
                const message = JSON.parse(data);
                switch (message.type) {
                    case PubSubDataType.CMD:
                        if (message.data === PubSubCMD.REFRESH_COMMENTS) {
                            console.log('cmd to refresh comments');
                            const { commentOnID, selfOnly } = fetchParamsRef.current;
                            fetchComments(commentOnID, { selfOnly }).catch((err) => {
                                console.error('Error refreshing comments:', err);
                            });
                        }
                        break;
                    default:
                        console.log('dont know what to do with this message');
                        break;
                }
            } catch (error) {
                console.err('Error parsing message');
            }
        };
        wsManager.addMessageListener(handleMessage);
        // Cleanup function to remove the listener when the component unmounts
        return () => {
            wsManager.removeMessageListener(handleMessage);
        };
    }, [wsManager]);

    const fetchComments = useCallback(async (commentOnID, { selfOnly = false }) => {
        setFetching(true);
        abortControllerRef.current = new AbortController();
        const query = {
            id: commentOnID,
            selfOnly: selfOnly,
        };
        const queryString = new URLSearchParams(query).toString();
        fetchParamsRef.current = { commentOnID, selfOnly };
        try {
            const response = await fetch(`${config.apiURL}/comments?${queryString}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });

            //Uses response.ok to check for successful responses, which covers all 2xx status codes.
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while updating the order');
            }
            const data = await response.json();
            setComments(data.comments || []);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch comments:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    fetchComments.propTypes = {
        commentOnID: PropTypes.string.isRequired,
        options: PropTypes.shape({
            selfOnly: PropTypes.bool,
        }),
    };

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, comments, fetchComments, abort };
}

export default useComments;
